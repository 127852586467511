const ENTITY_TAGS = [
  {
    id: 1,
    name: "ORGANIZATION",
    color: "rgb(0, 146, 194)",
    tooltip:
      "An organization could be an corporation, company, ngo, club, society, union...",
  },
  {
    id: 2,
    name: "PERSON",
    color: "rgb(0, 198, 141)",
    tooltip: "Person includes real people as well as fictional persons.",
  },
  {
    id: 3,
    name: "PRODUCT",
    color: "rgb(57, 219, 216)",
    tooltip:
      "Includes consumer goods like mobile phones as well as organization related services.",
  },
  {
    id: 4,
    name: "EVENT",
    color: "rgb(255, 98, 107)",
    tooltip:
      "Event descripes classical events like quarterly reports, fairs as well as occasions, incidents etc.",
  },
  {
    id: 5,
    name: "LOCATION",
    color: "rgb(255, 100, 198)",
    tooltip:
      "Location descripse geographical landmarks like countries or regions or cities as well as buildings like Eifel Tower.",
  },
  {
    id: 6,
    name: "WORK_OF_ART",
    color: "rgb(133, 117, 205)",
    tooltip: "Work of art descripes artwork like films, patents etc.",
  },
  {
    id: 7,
    name: "OTHER",
    color: "rgb(101, 193, 234)",
    tooltip:
      "Everithing that doesn't fit in one of the former categories is classified as Other.",
  },
];

export function getEntityTags() {
  return new Promise((resolve) => {
    resolve(ENTITY_TAGS);
  });
}
