import React, { Component } from "react";
import {
  faLaugh as falFaLaugh,
  faSmile as falFaSmile,
  faMeh as falFaMeh,
  faMehBlank as falMehBlank,
  faFrown as falFaFrown,
  faAngry as falFaAngry,
} from "@fortawesome/pro-light-svg-icons";
import {
  faLaugh as fasFaLaugh,
  faSmile as fasFaSmile,
  faMeh as fasFaMeh,
  faMehBlank as fasMehBlank,
  faFrown as fasFaFrown,
  faAngry as fasFaAngry,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const SENTIMENT_CLASSIFIERS = [
  {
    id: 1,
    name: "very_negative",
    icon: { light: falFaAngry, solid: fasFaAngry },
    color: "#d50000",
    tooltip: "very negative",
  },
  {
    id: 2,
    name: "negative",
    icon: { light: falFaFrown, solid: fasFaFrown },
    color: "#ff5131",
    tooltip: "negative",
  },
  {
    id: 3,
    name: "neutral",
    icon: { light: falMehBlank, solid: fasMehBlank },
    color: "#677078",
    tooltip: "neutral",
  },
  {
    id: 4,
    name: "ambivalent",
    icon: { light: falFaMeh, solid: fasFaMeh },
    color: "#ffd600",
    tooltip: "ambivalent",
  },
  {
    id: 5,
    name: "positive",
    icon: { light: falFaSmile, solid: fasFaSmile },
    color: "#76d275",
    tooltip: "positive",
  },
  {
    id: 6,
    name: "extremly_positive",
    icon: { light: falFaLaugh, solid: fasFaLaugh },
    color: "#43a047",
    tooltip: "extremly positive",
  },
];

class EntityCard extends Component {
  constructor(props) {
    super(props);
    this.state = { sentimentClassifierHover: null };
    this.handleAspect = this.handleAspect.bind(this);
    this.handleSentiment = this.handleSentiment.bind(this);
  }

  handleAspect() {
    const i = this.props.index;
    const aspect = JSON.parse(!this.props.aspect);
    this.props.handleAspect(i, aspect);
  }

  handleSentiment(value) {
    const i = this.props.index;
    this.props.handleSentiment(i, value);
  }

  toggleSentimentHover(value) {
    this.setState({ sentimentClassifierHover: value });
  }

  handleDelete() {
    const i = this.props.index;
    this.props.handleDelete(i);
  }

  render() {
    return (
      <div className="card paper-card entity-card">
        <div className="card-body">
          <div
            className="entity-tag"
            style={{ backgroundColor: this.props.color }}
          >
            {this.props.tag}
          </div>
          <div className="card-title">
            <span>{this.props.name}</span>
          </div>
          <div className="entity-aspect">
            <span>Is aspect:</span>
            <div
              className={this.props.aspect ? "toggle checked" : "toggle"}
              role="checkbox"
              aria-checked={this.props.aspect}
            >
              <div
                className="toggle-button"
                data-tip
                data-for={`aspect${this.props.index}`}
                onClick={this.handleAspect}
              >
                <span className="toggle-feature"></span>
              </div>
              <ReactTooltip
                id={`aspect${this.props.index}`}
                place="right"
                effect="solid"
                delayShow={500}
              >
                Aspects are all nouns except propper names.
              </ReactTooltip>
            </div>
          </div>
          <div className="entity-sentiment">
            <span style={{ marginBottom: "0.75rem" }}>Choose sentiment:</span>
            <div className="sentiment-ratings">
              {SENTIMENT_CLASSIFIERS.map((sentimentClassifier, index) => (
                <React.Fragment key={index}>
                  <div
                    className="sentiment-rating-item"
                    data-tip
                    data-for={`sentimentClassifier${index}`}
                    style={{
                      color:
                        this.state.sentimentClassifierHover ===
                          sentimentClassifier.name ||
                        this.props.sentimentClassifier ===
                          sentimentClassifier.name
                          ? sentimentClassifier.color
                          : "inherit",
                    }}
                  >
                    <FontAwesomeIcon
                      onMouseEnter={() =>
                        this.toggleSentimentHover(sentimentClassifier.name)
                      }
                      onMouseLeave={() => this.toggleSentimentHover(null)}
                      onClick={() =>
                        this.handleSentiment(sentimentClassifier.name)
                      }
                      icon={
                        this.state.sentimentClassifierHover ===
                          sentimentClassifier.name ||
                        this.props.sentimentClassifier ===
                          sentimentClassifier.name
                          ? sentimentClassifier.icon.solid
                          : sentimentClassifier.icon.light
                      }
                    />
                  </div>
                  <ReactTooltip
                    id={`sentimentClassifier${index}`}
                    place="bottom"
                    effect="solid"
                    delayShow={500}
                  >
                    {sentimentClassifier.tooltip}
                  </ReactTooltip>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button
            type="button"
            onClick={() => this.handleDelete()}
            className="btn btn-link"
          >
            Delete
          </button>
        </div>
      </div>
    );
  }
}

export default EntityCard;
