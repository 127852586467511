import React, { Component } from "react";

class EmptyStateContainer extends Component {
  state = {};

  render() {
    return (
      <div className="empty-state-container">
        <div className="image-container">
          <img
            ng-src={this.props.image}
            width="300"
            height="300"
            src={this.props.image}
            onLoad={this.props.handleLoading}
            alt=""
          />
        </div>
        <div className="headline">
          <p>{this.props.headline}</p>
        </div>

        {this.props.callToAction ? (
          <div className="call-to-action">{this.props.callToAction}</div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default EmptyStateContainer;
