import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Security } from "@okta/okta-react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import logger from "./services/logService";

const config = {
  issuer: "https://dev-149465.okta.com/oauth2/default",
  redirectUri: window.location.origin + "/implicit/callback",
  clientId: "0oa4q01twM1yF3N4s4x6",
  pkce: true,
};

logger.init();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Security {...config}>
        <App />
      </Security>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
