import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { LoginCallback, SecureRoute, withOktaAuth } from "@okta/okta-react";
import PageLoader from "./components/pageLoader";
import AppContext from "./appContext";
import Main from "./components/main";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { userToken: null, userInfo: null };
    this.checkUser = this.checkUser.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  async componentDidMount() {
    this.checkUser();
  }

  async componentDidUpdate() {
    this.checkUser();
  }

  async checkUser() {
    if (this.props.authState.isAuthenticated && !this.state.userInfo) {
      const userInfo = await this.props.authService.getUser();
      this.setState({
        userToken: this.props.authState.accessToken,
        userInfo: userInfo
      });
    }
  }

  async handleLogout() {
    this.props.authService.logout("/");
  }

  render() {
    return (
      <React.Fragment>
        <AppContext.Provider
          value={{
            userToken: this.state.userToken,
            userInfo: this.state.userInfo,
            logout: this.handleLogout
          }}
        >
          <Switch>
            <Route path="/implicit/callback" component={LoginCallback} />
            <SecureRoute path="/" component={Main} />
          </Switch>
        </AppContext.Provider>
        {!this.state.userToken && !this.state.userInfo && (
          <PageLoader style={{ zIndex: "1200" }} />
        )}
      </React.Fragment>
    );
  }
}

export default withOktaAuth(App);
