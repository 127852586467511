import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

class LanguageCard extends Component {
  state = {};
  render() {
    return (
      <div className="card paper-card language-card">
        <div className="card-body">
          <div className="card-title" style={{ paddingBottom: "10px" }}>
            {this.props.name}
          </div>
          <div className="progress-meter">
            <div className="progress-meter-heading">
              <span className="progress-meter-label">Training progress</span>
            </div>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${(this.props.progress / 10000) * 100}%` }}
                aria-valuenow={(this.props.progress / 10000) * 100}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div>
              <div
                className="progress-meter-bucket-label"
                data-tip
                data-for="progressMeterTooltip"
              >
                {`${this.props.progress} / 10000`}
              </div>
            </div>
            <ReactTooltip
              id="progressMeterTooltip"
              place="bottom"
              effect="solid"
              data-multiline={true}
              delayShow={1000}
            >
              Counts the number of accepted ratings.
              <br />
              An annotated sentence is accepted, as soon as second person marks
              <br />
              the entities, the categories and the sentiment values in the same
              way.
            </ReactTooltip>
          </div>
          <div className="card-link-footer">
            <Link
              to={`/training/text-classification/${this.props.type}/${this.props.code}`}
              className="card-link"
            >
              Start training
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default LanguageCard;
