import React, { Component } from "react";
import { Link } from "react-router-dom";
import PageLoader from "../components/pageLoader";
import TitleBar from "../components/titleBar";
import KnowledgeGraphImage from "../images/knowledge-graph.png";
import EmptySateContainer from "./emptyStateContainer";

class KnowledgeGraph extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
    this.handleLoading = this.handleLoading.bind(this);
  }

  handleLoading() {
    this.setState({ loading: false });
  }

  render() {
    return (
      <React.Fragment>
        <TitleBar title="Knowledge Graph" />
        <div className="container">
          <EmptySateContainer
            image={KnowledgeGraphImage}
            handleLoading={this.handleLoading}
            headline={"The Knowledge Graph section is coming soon."}
            callToAction={
              <React.Fragment>
                <p>
                  Help us to make this happen by training the machine how to
                  classify texts. Decide if you want to do this for{" "}
                  <Link to="/training/text-classification/traditional">
                    traditional content
                  </Link>{" "}
                  or for{" "}
                  <Link to="/training/text-classification/social">
                    social media items
                  </Link>
                  .
                </p>
              </React.Fragment>
            }
          />
        </div>
        {this.state.loading && <PageLoader />}
      </React.Fragment>
    );
  }
}

export default KnowledgeGraph;
