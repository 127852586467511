import React, { Component } from "react";

class PageLoader extends Component {
  state = {};
  render() {
    return (
      <div className="page-loader" style={this.props.style}>
        <div className="loader">
          <div className="loader-icon"></div>
        </div>
      </div>
    );
  }
}

export default PageLoader;
