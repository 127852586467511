import React, { Component } from "react";
import AppContext from "../../../appContext";
import { getLanguages } from "../../../services/languageService";
// import http from "../../../services/httpService";
// import { apiEndpoint } from "../../../config.json";
import PageLoader from "../../pageLoader";
import TitleBar from "../../titleBar";
import LanguageCard from "./languageCard";

class LanguageList extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      type: null,
      languages: [],
    };
    // this.getLanguages = this.getLanguages.bind(this);
  }

  async componentDidMount() {
    const { type } = this.props.match.params;
    const userToken = this.context.userToken;

    console.log(userToken);

    const { data } = await getLanguages(type, userToken);
    this.setState({ loading: false, type: type, languages: data });

    // this.getLanguages();
  }

  // async getLanguages() {
  //   if (this.context.userToken) {
  //     const { type } = this.props.match.params;

  //     try {
  //       const { data: languages } = await http.get(
  //         apiEndpoint + "/training/text-classification/" + type + "/languages",
  //         {
  //           headers: {
  //             Authorization: `Bearer ${this.context.userToken}`,
  //           },
  //         }
  //       );
  //       if (languages.length >= 0) {
  //         this.setState({ loading: false, languages: languages });
  //       }
  //     } catch (ex) {
  //       if (ex.response && ex.response.status === 401) alert("Unauthorized");
  //       console.log(ex);
  //     }
  //   }
  // }

  render() {
    return (
      <React.Fragment>
        <TitleBar
          title={
            <React.Fragment>
              Text Classification:
              <span
                style={{ paddingLeft: "0.2em", textTransform: "capitalize" }}
              >
                {this.state.type}
              </span>
            </React.Fragment>
          }
        />
        <div className="container">
          <div className="meta-bar">
            <div className="meta-bar-title">
              <h2>Select Language</h2>
              <span>Please select a language you are familiar with.</span>
            </div>
          </div>
          <div className="row">
            {this.state.languages.map((language) => (
              <div className="col-4" key={language.code}>
                <LanguageCard
                  code={language.code}
                  name={language.name}
                  type={this.state.type}
                  progress={language.progress}
                />
              </div>
            ))}
          </div>
        </div>
        {this.state.loading && <PageLoader />}
      </React.Fragment>
    );
  }
}

export default LanguageList;
