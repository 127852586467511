import React, { Component } from "react";
import AppContext from "../../../appContext";
import ReactTooltip from "react-tooltip";
import http from "../../../services/httpService";
import { apiEndpoint } from "../../../config.json";
import { getLanguage } from "../../../services/languageService";
import { getEntityTags } from "../../../services/entityTagService";
import { TextAnnotator as Annotator } from "react-text-annotate";
import PageLoader from "../../pageLoader";
import TitleBar from "../../titleBar";
import EntityCard from "../../entityCard";

import {
  faLongArrowLeft as falLongArrowLeft,
  faLongArrowRight as falLongArrowRight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class TextAnnotator extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      type: null,
      language: null,
      loading: true,
      disabled: false,
      disableNext: true,
      disablePrevious: true,
      sentences: [],
      entities: [],
      entityTags: [],
      entityTag: null,
    };
    this.getSentences = this.getSentences.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
    this.handleTextAnnotation = this.handleTextAnnotation.bind(this);
    this.handleAspect = this.handleAspect.bind(this);
    this.handleSentiment = this.handleSentiment.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleNext = this.handleNext.bind(this);

    this.getSentenceType = this.getSentenceType.bind(this);
    this.getLanguage = this.getLanguage.bind(this);
    this.getEntityTags = this.getEntityTags.bind(this);
  }

  async componentDidMount() {
    const sentenceType = this.getSentenceType();
    const language = await this.getLanguage();
    const entityTags = await this.getEntityTags();

    this.setState({ sentenceType, language, entityTags });

    this.getSentences();
  }

  getSentenceType() {
    const sentenceType = this.props.match.params.type;
    return sentenceType;
  }

  async getLanguage() {
    const languageCode = this.props.match.params.code;
    const language = await getLanguage(languageCode);
    return language;
  }

  async getEntityTags() {
    const entityTags = await getEntityTags();
    return entityTags;
  }

  async getSentences() {
    if (this.context.userToken && this.context.userInfo) {
      const { type, code } = this.props.match.params;

      try {
        const { data: sentences } = await http.get(
          apiEndpoint +
            "/training/text-classification/" +
            type +
            "/sentences/" +
            code,
          {
            headers: {
              Authorization: `Bearer ${this.context.userToken}`,
            },
            params: {
              userID: this.context.userInfo.sub,
            },
          }
        );

        if (sentences.length >= 0) {
          this.setState({
            sentences: sentences,
            loading: false,
            disableNext: true,
            entities: [],
            disabled: false,
            entityTag: this.state.entityTags[0].name,
          });
        }
      } catch (ex) {
        if (ex.response && ex.response.status === 401) alert("Unauthorized");
        console.log(ex);
      }
    }
  }

  handleClearAll() {
    this.setState({
      entities: [],
      disableNext: true,
      entityTag: this.state.entityTags[0].name,
    });
  }

  handleTextAnnotation(entities) {
    if (entities.length > this.state.entities.length) {
      const entity = entities[entities.length - 1];
      entity.aspect = false;
      entity.sentimentClassifier = false;
      this.setState({ entities: [...this.state.entities, entity] });
    } else {
      this.setState({ entities });
    }

    this.handleUserInput(entities);
  }

  handleAspect(i, value) {
    const entities = [...this.state.entities];
    const entity = {
      ...entities[i],
      aspect: value,
    };
    entities[i] = entity;
    this.handleUserInput(entities);
    this.setState({ entities });
  }

  handleSentiment(i, value) {
    const entities = [...this.state.entities];
    const entity = {
      ...entities[i],
      sentimentClassifier: value,
    };
    entities[i] = entity;
    this.handleUserInput(entities);
    this.setState({ entities });
  }

  handleDelete(i) {
    let entities = [...this.state.entities];
    entities.splice(i, 1);
    this.setState({ entities });
  }

  handleUserInput(entities) {
    let disabled = false;
    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      if (entity.sentimentClassifier === false) {
        disabled = true;
      }
    }
    this.setState({ disableNext: disabled });
  }

  async handleNext() {
    if (this.context.userToken && this.context.userInfo) {
      const entities = this.state.entities;

      const data = {
        userID: this.context.userInfo.sub,
        sentence: this.state.sentences[0],
        entities: entities,
      };

      try {
        await http.post(
          apiEndpoint + "/training/text-classification/entities",
          data,
          {
            headers: {
              Authorization: `Bearer ${this.context.userToken}`,
            },
          }
        );
        this.getSentences();
      } catch (ex) {
        if (ex.response && ex.response.status === 401) alert("Unauthorized");
        console.log(ex);
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <TitleBar
          title={
            <React.Fragment>
              Text Classification:
              <span style={{ padding: "0 0.2em", textTransform: "capitalize" }}>
                {this.state.sentenceType &&
                  this.state.language &&
                  this.state.sentenceType + " - " + this.state.language.name}
              </span>
            </React.Fragment>
          }
        />
        <div
          className="container"
          style={{ paddingBottom: "120px" }}
          disabled={this.state.disabled}
        >
          <div className="meta-bar">
            <div className="meta-bar-title">
              <h2>Annotate the Sentence</h2>
              <span>
                1. In the first step you should find and categorize all entities
                you find in the sentence below. In the next step you will be
                asked to do a sentimental analysis.
              </span>
            </div>
          </div>
          <div
            className="actionbar"
            style={{
              marginLeft: "8px",
              marginBottom: "6px",
            }}
          >
            {this.state.entityTags.map((tag) => (
              <React.Fragment key={tag.id}>
                <button
                  className="button-round button-secondary"
                  data-tip
                  data-for={`tag${tag.id}`}
                  onClick={() => this.setState({ entityTag: tag.name })}
                  style={
                    this.state.entityTag === tag.name
                      ? {
                          backgroundColor: tag.color,
                          color: "#fff",
                          borderColor: tag.color,
                        }
                      : {}
                  }
                >
                  {tag.name}
                </button>
                <ReactTooltip
                  id={`tag${tag.id}`}
                  place="bottom"
                  effect="solid"
                  delayShow={1000}
                >
                  {tag.tooltip}
                </ReactTooltip>
              </React.Fragment>
            ))}
            <button
              className="button-link"
              data-tip
              data-for="clearAll"
              style={{ marginLeft: "auto" }}
              onClick={this.handleClearAll}
            >
              Clear All
            </button>
            <ReactTooltip
              id="clearAll"
              place="bottom"
              effect="solid"
              delayShow={1000}
            >
              Clear all annotations
            </ReactTooltip>
          </div>
          <div className="card text-annotator-card">
            <div className="card-body">
              <div>
                {this.state.sentences.map((sentence) => (
                  <Annotator
                    key={sentence.id}
                    style={{
                      lineHeight: 1.5,
                      fontSize: "16px",
                      color: "#34404b",
                    }}
                    content={sentence.text}
                    value={this.state.entities}
                    onChange={(entities) => this.handleTextAnnotation(entities)}
                    getSpan={(span) => ({
                      ...span,
                      tag: this.state.entityTag,
                      color: this.state.entityTags.find((tag) => {
                        return tag.name === this.state.entityTag;
                      }).color,
                    })}
                  />
                ))}
              </div>
            </div>
          </div>
          {this.state.entities.length > 0 && (
            <div>
              <div className="meta-bar">
                <div className="meta-bar-title">
                  <span>
                    2. Please identify if the entity is an aspect and determine
                    the sentiment for all entities.
                  </span>
                </div>
              </div>
              <div className="row no-gutters">
                {this.state.entities.map((entity, index) => (
                  <div className="col-4" key={index}>
                    <EntityCard
                      index={index}
                      name={entity.text}
                      tag={entity.tag}
                      aspect={entity.aspect}
                      color={
                        this.state.entityTags.find((entityTag) => {
                          return entityTag.name === entity.tag;
                        }).color
                      }
                      sentimentClassifier={entity.sentimentClassifier}
                      handleAspect={this.handleAspect}
                      handleSentiment={this.handleSentiment}
                      handleDelete={this.handleDelete}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="toolbar">
            <div data-tip data-for="previousBtn">
              <button
                className="button-secondary button-circle"
                onClick={this.handlePrevious}
                disabled={this.state.disablePrevious}
                style={{ marginRight: "15px" }}
              >
                <FontAwesomeIcon icon={falLongArrowLeft} />
              </button>
            </div>
            <ReactTooltip
              id="previousBtn"
              place="left"
              effect="solid"
              delayShow={1000}
            >
              Previous sentence feature will come in the near futur.
            </ReactTooltip>
            <div data-tip data-for="nextBtn">
              <button
                className="button-primary button-circle"
                onClick={() => {
                  this.handleNext();
                  this.setState({ disabled: true });
                }}
                disabled={this.state.disableNext}
                style={{ marginLeft: "15px" }}
              >
                <FontAwesomeIcon icon={falLongArrowRight} />
              </button>
            </div>
            {this.state.disableNext && (
              <ReactTooltip
                id="nextBtn"
                place="right"
                effect="solid"
                delayShow={1000}
              >
                Please annotate the sentence above and add some sentiment
                classification to each entity.
              </ReactTooltip>
            )}
          </div>
        </div>
        {this.state.loading && <PageLoader />}
      </React.Fragment>
    );
  }
}

export default TextAnnotator;
