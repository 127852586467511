import Raven from "raven-js";

function init() {
  Raven.config(
    "https://4e0e72c2a08b4e55bd7373f713d61181@o359770.ingest.sentry.io/5194841",
    {
      release: "0-6-0",
      environment: "development-test",
    }
  ).install();
}

function log(error) {
  // Raven.captureException(error);
  console.log(error);
}

export default {
  init,
  log,
};
