import React, { Component } from "react";
import AppContext from "../appContext";
import { Link, withRouter } from "react-router-dom";
import { ReactComponent as Logo } from "../images/logo.svg";
import { ReactComponent as User } from "../images/user.svg";
import { ReactComponent as ChevronDown } from "../images/chevron-down.svg";
import { ReactComponent as ChevronUp } from "../images/chevron-up.svg";

class Navbar extends Component {
  static contextType = AppContext;

  handleLogout = () => {
    this.context.logout();
  };

  render() {
    return (
      <nav className="navbar navbar-expand fixed-top navbar-dark bg-dark">
        <Link className="navbar-brand" to="/">
          <span>
            <Logo width="85.7" height="25" />
          </span>
        </Link>
        <ul className="navbar-nav navbar-main-nav mr-auto">
          <li className="nav-item">
            <span className="nav-link-wrapper">
              <Link
                className={`nav-link ${
                  this.props.location.pathname === "/" && "active"
                }`}
                to="/"
                style={{ fontSize: "18px", height: "65px" }}
              >
                NLP
              </Link>
            </span>
          </li>
          <li className="nav-item">
            <span className="nav-link-wrapper">
              <Link
                className={`nav-link ${
                  this.props.location.pathname === "/knowledge-graph" &&
                  "active"
                }`}
                to="/knowledge-graph"
                style={{ fontSize: "18px", height: "65px" }}
              >
                KnowledgeGraph
              </Link>
            </span>
          </li>
          <li
            className="nav-item dropdown"
            onClick={this.handleClick}
            onBlur={this.handleClick}
          >
            <span className="nav-link-wrapper">
              <a
                className={`nav-link dropdown-toggle ${
                  (this.props.location.pathname ===
                    "/training/text-classification/journalistic" ||
                    this.props.location.pathname ===
                      "/training/text-classification/social" ||
                    this.props.location.pathname ===
                      "/training/text-classification/social/de" ||
                    this.props.location.pathname ===
                      "/training/text-classification/social/en" ||
                    this.props.location.pathname ===
                      "/training/text-classification/journalistic/de" ||
                    this.props.location.pathname ===
                      "/training/text-classification/journalistic/en") &&
                  "active"
                }`}
                href="/#"
                id="navbarDropdownMainMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ fontSize: "18px", height: "65px" }}
              >
                <span>Training</span>
                <span className="dropdown-toggle-addon">
                  <ChevronDown
                    className="chevron-down"
                    width="10"
                    height="10"
                  />
                  <ChevronUp className="chevron-up" width="10" height="10" />
                </span>
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMainMenuLink"
              >
                <h6 className="dropdown-header">Text Classification</h6>
                <Link
                  className={`dropdown-item ${
                    this.props.location.pathname ===
                      "/training/text-classification/journalistic" && "active"
                  }`}
                  to="/training/text-classification/journalistic"
                >
                  Journalistic
                </Link>
                <Link
                  className={`dropdown-item ${
                    this.props.location.pathname ===
                      "/training/text-classification/social" && "active"
                  }`}
                  to="/training/text-classification/social"
                >
                  Social
                </Link>
              </div>
            </span>
          </li>
        </ul>
        <ul className="navbar-nav">
          <li className="nav-item nav-item-user dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/#"
              id="navbarDropdownUserMenuLink"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ fontSize: "18px", height: "40px" }}
            >
              <User width="24" height="24" style={{ marginRight: "14px" }} />
              <span>
                {this.context.userInfo ? this.context.userInfo.given_name : ""}
              </span>
            </a>

            <div
              className="dropdown-menu"
              aria-labelledby="navbarDropdownUserMenuLink"
            >
              <a
                className="dropdown-item"
                href="https://cision.service-now.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Create a Ticket
              </a>
              <a
                className="dropdown-item"
                href="https://cision.service-now.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Knowledge Guide
              </a>
              <div className="dropdown-divider"></div>
              <button
                className="btn btn-link dropdown-item"
                onClick={this.handleLogout}
              >
                Log Out
              </button>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}

export default withRouter(Navbar);
