import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Navbar from "./navbar";
import NaturalLanguageProcessing from "./naturalLanguageProcessing";
import KnowledgeGraph from "./knowledgeGraph";
import LanguageList from "./training/text-classification/languageList";
import TextAnnotator from "./training/text-classification/textAnnotator";

class Main extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <Switch>
          <Route path={"/knowledge-graph"} component={KnowledgeGraph} />
          <Route
            path={"/training/text-classification/:type/:code"}
            component={TextAnnotator}
          />
          <Route
            path={"/training/text-classification/:type"}
            component={LanguageList}
          />
          <Route component={NaturalLanguageProcessing} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Main;
