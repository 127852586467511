import http from "./httpService";

const apiEndpoint = "https://ci-nlp-workbench-api.herokuapp.com/api";

const LANGUAGES = [
  { code: "de", name: "German" },
  { code: "en", name: "English" },
];

export function getLanguages(type, userToken) {
  return http.get(
    apiEndpoint + "/training/text-classification/" + type + "/languages",
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
}

export function getLanguage(code) {
  return new Promise((resolve) => {
    const language = LANGUAGES.find((lang) => {
      return lang.code === code;
    });

    resolve(language);
  });
}
